<template>
    <el-form :model="dataSource" status-icon :rules="pwdValidateRule" ref="_pwdInfoForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="用户名">
            <el-input placeholder="admin"
                      :disabled="true">
            </el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="NewPassword">
            <el-input type="password" v-model="dataSource.NewPassword" :maxlength="32" :minlength="8" auto-complete="off" placeholder="请输入新密码"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="ConfirmPassword">
            <el-input type="password" v-model="dataSource.ConfirmPassword" :maxlength="32" :minlength="8" auto-complete="off" placeholder="请再次输入密码"></el-input>
        </el-form-item>
    </el-form>
</template>
<script>
    export default {
        data() {
            var validateNewPwd = (rule, value, callback) => {
                if (value === '' || !value) return callback(new Error('密码不能为空'));
                else {
                    // if (value.length < 8) {
                    //     return callback(new Error('密码至少八位或者八位以上'));
                    // }
                    // var patrn = /^(?![a-zA-z]+$)(?!\d+$)(?![!@#$%^&*]+$)(?![a-zA-z\d]+$)(?![a-zA-z!@#$%^&*]+$)(?![\d!@#$%^&*]+$)[a-zA-Z\d!@#$%^&*]+$/;
                    // if (!patrn.exec(value)) return callback(new Error('密码必须包含数字，字母，特殊字符'));
                    //if (this.dataSource.ReUserPassword !== '') {
                        //this.$refs._userInfoForm.validateField('NewPassword');
                    //}
                }
                callback();

            };

            var validateRePwd = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('请再次输入密码'));
                } else if (value !== this.dataSource.NewPassword) {
                    callback(new Error('两次输入密码不一致!'));
                } else {
                    callback();
                }
            };
            return {
                dataSource: {
                    NewPassword: '',
                    ConfirmPassword: ''
                },
                pwdValidateRule: {
                    NewPassword: [
                        { validator: validateNewPwd, trigger: 'blur' }
                    ],
                    ConfirmPassword: [
                        { validator: validateRePwd, trigger: 'blur' }
                    ]
                }
            };
        },
        props: ['OptEnterpriseID','EnterpriseName'],
        methods: {
            resetPwd(options) {
                this.$refs["_pwdInfoForm"].validate((valid) => {
                    console.log(valid);
                    if (valid) {
                        var param = { OptEnterpriseID: this.OptEnterpriseID, NewPassword: this.dataSource.NewPassword, ConfirmPassword: this.dataSource.ConfirmPassword };
                        param.NewPassword = this.$crypto.sm2Encrypt(param.NewPassword);
                        param.ConfirmPassword = this.$crypto.sm2Encrypt(param.ConfirmPassword);
                        this.$ajax.send("omsapi/optenterprise/resetpwd", "post", param,
                        (data) => {
                            this.Utils.messageBox("密码重置成功.", "success");
                            options.visible = false;
                            this.resetForm();

                        },(data) => {
                            options.visible = true;
                            this.Utils.messageBox(data, "error");
                        });
                    } else {
                        options.visible = true;
                        return false;
                    }
                });
            },
            resetForm() {
                this.$refs["_pwdInfoForm"].resetFields();
            }
        }
    }
</script>
<style scoped>
</style>